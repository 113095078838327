import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient, 
    private utilService: UtilService
  ) { }

  // usuario

  adicionarUsuario(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarUsuario';
    var header = this.utilService.obterHeaderApi();
    return this.http.post(url, form, header).toPromise();
  }

  atualizarUsuario(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarUsuario';
    var header = this.utilService.obterHeaderApi();
    return this.http.put(url, form, header).toPromise();
  }


  selecionarUsuarioNome(Nome:string) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarUsuarioNome/' + Nome;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  selecionarUsuarioId(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarUsuarioId/' + Id;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();
  }

  selecionarUsuarioLogin(email:string, senha:string) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarUsuarioLogin/' + email + '/' + senha;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }
  
  // paciente

  adicionarPaciente(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarPaciente';
    var header = this.utilService.obterHeaderApi();
    return this.http.post(url, form, header).toPromise();
  }

  atualizarPaciente(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarPaciente';
    var header = this.utilService.obterHeaderApi();
    return this.http.put(url, form, header).toPromise();
  }

  atualizarPacienteVinculo(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarPacienteVinculo';
    var header = this.utilService.obterHeaderApi();
    return this.http.put(url, form, header).toPromise();
  }


  selecionarPacienteNome(Nome:string) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarPacienteNome/' + Nome ;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();
  }

  selecionarPacienteCpf(Cpf:string) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarPacienteCpf/' + Cpf ;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();
  }


  selecionarPacienteId(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarPacienteId/' + Id;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();
  }

  selecionarPacienteVinculo(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarPacienteVinculo/' + Id;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();
  }

  selecionarComorbidade() {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarComorbidade' ;
    return this.http.get(url).toPromise();

  }

  // empresa

  adicionarEmpresa(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarEmpresa';
    var header = this.utilService.obterHeaderApi();
    return this.http.post(url, form, header).toPromise();
  }

  atualizarEmpresa(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarEmpresa';
    var header = this.utilService.obterHeaderApi();
    return this.http.put(url, form, header).toPromise();
  }


  selecionarEmpresaNome(Nome:string) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarEmpresaNome/' + Nome;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  selecionarEmpresaId(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarEmpresaId/' + Id;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  // produto

  adicionarProduto(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarProduto';
    var header = this.utilService.obterHeaderApi();
    return this.http.post(url, form, header).toPromise();
  }

  atualizarProduto(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarProduto';
    var header = this.utilService.obterHeaderApi();
    return this.http.put(url, form, header).toPromise();
  }


  selecionarProdutoNome(Nome:string) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarProdutoNome/' + Nome;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  selecionarProdutoId(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarProdutoId/' + Id;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

   // contrato

   adicionarContrato(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarContrato';
    var header = this.utilService.obterHeaderApi();
    return this.http.post(url, form, header).toPromise();
  }

  atualizarContrato(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarContrato';
    var header = this.utilService.obterHeaderApi();
    return this.http.put(url, form, header).toPromise();
  }


  selecionarContratoNome(Nome:string) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarContratoNome/' + Nome;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  selecionarContratoId(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarContratoId/' + Id;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  // pagamento

  adicionarPagamento(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarPagamento';
    var header = this.utilService.obterHeaderApi();
    return this.http.post(url, form, header).toPromise();
  }

  atualizarPagamento(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarPagamento';
    var header = this.utilService.obterHeaderApi();
    return this.http.put(url, form, header).toPromise();
  }


  selecionarPagamentoNome(Nome:string) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarProdutoPagamentoNome/' + Nome;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  selecionarPagamentoId(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarPagamentoId/' + Id;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  

  // veiculo

  adicionarVeiculo(form: any) {                   
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarVeículo';
    var header = this.utilService.obterHeaderApi();
    return this.http.post(url, form, header).toPromise();
  }

  atualizarVeiculo(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarVeículo';
    var header = this.utilService.obterHeaderApi();
    return this.http.put(url, form, header).toPromise();
  }


  selecionarVeiculoNome(Nome:string) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarVeículoNome/' + Nome;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  selecionarVeiculoId(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarVeiculoId/' + Id;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }


  // remocao

  adicionarRemocao(form: any) {                   
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarRemocao';
    var header = this.utilService.obterHeaderApi();
    return this.http.post(url, form, header).toPromise();
  }

  atualizarRemocao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarRemocao';
    var header = this.utilService.obterHeaderApi();
    return this.http.put(url, form, header).toPromise();
  }


  selecionarRemocaoNome(Nome:string) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarRemocaoNome/' + Nome;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  selecionarRemocaoRelatorio(form:any) {
    let Datainicial= form.Datainicial;
    let Datafinal= form.Datafinal;
    let Convenio= form.Convenio;
    let Tiporemocao= form.Tiporemocao;

    Datainicial= Datainicial.replace('-', '');
    Datainicial= Datainicial.replace('-', '');
  
    Datafinal= Datafinal.replace('-', '');
    Datafinal= Datafinal.replace('-', '');

    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarRemocaoRelatorio2/' + Datainicial + '/' + Datafinal + '/' + Convenio + '/' + Tiporemocao;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  selecionarRemocaoData(Data:string) {
    Data = Data.replace('-', '')
    Data = Data.replace('-', '')
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarRemocaoData2/' + Data;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  selecionarRemocaoId(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarRemocaoId/' + Id;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

   // remocao trecho

   adicionarRemocaotrecho(form: any) {                   
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarRemocaotrecho';
    var header = this.utilService.obterHeaderApi();
    return this.http.post(url, form, header).toPromise();
  }

  atualizarRemocaotrecho(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarRemocaotrecho';
    var header = this.utilService.obterHeaderApi();
    return this.http.put(url, form, header).toPromise();
  }


  selecionarRemocaotrechoNome(Nome:string) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarRemocaotrechoNome/' + Nome;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  selecionarRemocaotrechoRemocao(Remocao:number) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarRemocaotrechoRemocao/' + Remocao;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  selecionarRemocaotrechoId(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarRemocaotrechoId/' + Id;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

   // remocao vital

   adicionarRemocaovital(form: any) {                   
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarRemocaovital';
    var header = this.utilService.obterHeaderApi();
    return this.http.post(url, form, header).toPromise();
  }

  atualizarRemocaovital(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarRemocaovital';
    var header = this.utilService.obterHeaderApi();
    return this.http.put(url, form, header).toPromise();
  }


  selecionarRemocaovitalNome(Nome:string) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarRemocaovitalNome/' + Nome;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  selecionarRemocaovitalId(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarRemocaovitalId/' + Id;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  // mapa remoção localizacao

  adicionarRemocaolocalizacao(form: any) {                   
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarRemocaolocalizacao';
    var header = this.utilService.obterHeaderApi();
    return this.http.post(url, form, header).toPromise();
  }

  atualizarRemocaolocalizacao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizaRemocaolocalizacao';
    var header = this.utilService.obterHeaderApi();
    return this.http.put(url, form, header).toPromise();
  }


  selecionarRemocaolocalizacaoremocao(Remocao:number) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionaRemocaolocalizacaoRemocao/' + Remocao;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

  
  selecionarRemocaolocalizacaoid(Id:string) {
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionaRemocaolocalizacaoId/' + Id;
    var header = this.utilService.obterHeaderApi();
    return this.http.get(url, header).toPromise();

  }

    // estoque

    adicionarEstoque(form: any) {
      let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarEstoque';
      var header = this.utilService.obterHeaderApi();
      return this.http.post(url, form, header).toPromise();
    }
  
    atualizarEstoque(form: any) {
      let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarEstoque';
      var header = this.utilService.obterHeaderApi();
      return this.http.put(url, form, header).toPromise();
    }
  
  
    selecionarEstoqueNome(Nome:string) {
      let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarEstoqueNome/' + Nome;
      var header = this.utilService.obterHeaderApi();
      return this.http.get(url, header).toPromise();
  
    }
  
    selecionarEstoqueId(Id:number) {
      let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarEstoqueId/' + Id;
      var header = this.utilService.obterHeaderApi();
      return this.http.get(url, header).toPromise();
  
    }

    selecionarEstoqueGrupo(Grupo:number) {
      let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarEstoqueGrupo/' + Grupo;
      var header = this.utilService.obterHeaderApi();
      return this.http.get(url, header).toPromise();
  
    }

    // Estoque entrada

    
    adicionarEstoqueEntrada(form: any) {
      let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarEstoqueEntrada';
      var header = this.utilService.obterHeaderApi();
      return this.http.post(url, form, header).toPromise();
    }
  
    atualizarEstoqueEntrada(form: any) {
      let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarEstoqueEntrada';
      var header = this.utilService.obterHeaderApi();
      return this.http.put(url, form, header).toPromise();
    }
  
  
    selecionarEstoqueEntradaNota(Nome:string) {
      let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarEstoqueEntradaNota/' + Nome;
      var header = this.utilService.obterHeaderApi();
      return this.http.get(url, header).toPromise();
  
    }
  
    selecionarEstoqueEntradaId(Id:number) {
      let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarEstoqueEntradaId/' + Id;
      var header = this.utilService.obterHeaderApi();
      return this.http.get(url, header).toPromise();
  
    }

    selecionarEstoqueEntradaEstoque(Estoque:number) {
      let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarEstoqueEntradaEstoque/' + Estoque;
      var header = this.utilService.obterHeaderApi();
      return this.http.get(url, header).toPromise();
  
    }

     // Estoque saida

    
     adicionarEstoqueSaida(form: any) {
      console.log("FORM: " + JSON.stringify(form))
      let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarEstoqueSaida';
      var header = this.utilService.obterHeaderApi();
      return this.http.post(url, form, header).toPromise();
    }
  
    atualizarEstoqueSaida(form: any) {
      let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarEstoqueSaida';
      var header = this.utilService.obterHeaderApi();
      return this.http.put(url, form, header).toPromise();
    }
  
  
    selecionarEstoqueSaidaVeiculo(Veiculo:number) {
      let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarEstoqueSaidaVeiculo/' + Veiculo;
      var header = this.utilService.obterHeaderApi();
      return this.http.get(url, header).toPromise();
  
    }

    selecionarEstoqueSaidaEstoque(Estoque:number) {
      let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarEstoqueSaidaEstoque/' + Estoque;
      var header = this.utilService.obterHeaderApi();
      return this.http.get(url, header).toPromise();
  
    }
  
    selecionarEstoqueSaidaId(Id:number) {
      let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarEstoqueSaidaId/' + Id;
      var header = this.utilService.obterHeaderApi();
      return this.http.get(url, header).toPromise();
  
    }
  
}
