import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalController, LoadingController, AlertController, IonThumbnail } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ApiabertaService {

  constructor(
    private utilService:UtilService,
    private http: HttpClient, 
    private loadingController:LoadingController
  ) { }

   // tabeças do LISTMED
  async selecionarComorbidade() {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarComorbidadeAberta' ;
    loading.dismiss();

    return this.http.get(url).toPromise();

  }

  async selecionarApresentacao(tabela:string, colunas:string) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarTabelaAberta/' + tabela + '/' +colunas;
    loading.dismiss();

    return this.http.get(url).toPromise();

  }

  async selecionarEstoquegrupo(tabela:string, colunas:string) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarTabelaAberta/' + tabela + '/' +colunas;
    loading.dismiss();

    return this.http.get(url).toPromise();

  }

  async selecionarEvento(tabela:string, colunas:string) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarTabelaAberta/' + tabela + '/' +colunas;
    loading.dismiss();

    return this.http.get(url).toPromise();

  }

  // clientes

 async adicionarCliente(form: any) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarClienteAberta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();

    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  async atualizarCliente(form: any) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarClienteAberta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();

    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  async selecionarClienteId(Id:number) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarClienteIdAberta/'  + Id ;
    loading.dismiss();

    return this.http.get(url).toPromise();

  }

 async selecionarContratoId(Id:number) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarContratoIdAberta/' + Id;
    loading.dismiss();

    return this.http.get(url).toPromise();

  }

  // produtos

  async selecionarProdutoPessoa(Pessoa:number) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarProdutoPessoaAberta/'  + Pessoa ;
    loading.dismiss();

    return this.http.get(url).toPromise();

  }

  // divulgacao e orçamentos

 async adicionarDivulgacaoAberta(form: any) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarDivulgacaoAberta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();

    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  async atualizarClienteVinculo(form: any) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarClienteVinculoAberta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();

    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  // contrato

  async adicionarContrato(form: any) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarContratoAberta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();

    return this.http.post(url, form, { headers: headers }).toPromise();

  }

  async atualizarContrato(form: any) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarContratoAberta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();

    return this.http.put(url, form, { headers: headers }).toPromise();

  }

  async selecionarClienteVinculoAberta(Id:number) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_SelecionarClienteVinculoAberta/' + Id;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  // promessa de pagamento

  async adicionarPagamento(form: any) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_adicionarPagamentoAberta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();

    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  async atualizarPagamento(form: any) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_atualizarPagamentoAberta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();

    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  async selecionarPagamentoId(Id:number) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarPagamentoContratoAberta/'  + Id ;
    loading.dismiss();

    return this.http.get(url).toPromise();

  }

 async selecionarPagamentoContrato(Contrato:number) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarPagamentoContratoAberta/' + Contrato;
    loading.dismiss();
    return this.http.get(url).toPromise();

  }

  async loginCliente(Celular:string, Datanascimento:string) {
    let loading = await this.loadingController.create({ message: "aguarde..." });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Remove/_rm_selecionarLoginCliente/' + Celular + '/' + Datanascimento;
    loading.dismiss();
    return this.http.get(url).toPromise();

  }


}
