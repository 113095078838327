import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { File } from '@ionic-native/File/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { HttpClientModule } from '@angular/common/http'; // Importe aqui
import { ConsultaComponent } from './componentes/consulta/consulta.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RemocaoTrechoComponent } from './componentes/remocao-trecho/remocao-trecho.component';
import { MenuComponent } from './componentes/menu/menu.component';
import { CurrencyPipe } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';
import { FileUploadComponent } from './componentes/file-upload/file-upload.component';
import { Network } from '@ionic-native/network/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { EstoqueEntradaComponent } from './componentes/estoque-entrada/estoque-entrada.component';
import { EstoqueSaidaComponent } from './componentes/estoque-saida/estoque-saida.component';
import { DatePipe } from '@angular/common';
import { InfoComponent } from './componentes/info/info.component';
@NgModule({
  declarations: [
    AppComponent, 
    ConsultaComponent, 
    RemocaoTrechoComponent, 
    MenuComponent,
    FileUploadComponent,
    EstoqueEntradaComponent,
    EstoqueSaidaComponent,
    InfoComponent

  ], 
  entryComponents: [
    ConsultaComponent, 
    RemocaoTrechoComponent, 
    MenuComponent,
    FileUploadComponent,
    EstoqueEntradaComponent,
    EstoqueSaidaComponent,
    InfoComponent

  ],
  imports: [
    BrowserModule, 
    ReactiveFormsModule, 
    HttpClientModule , 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    FileUploadModule,
    NgxQRCodeModule ,
   ],
  providers: [
    StatusBar,
    SplashScreen,
    EmailComposer,
    CallNumber, 
    File ,
    FileOpener,
    Base64,
    CurrencyPipe,
    Network,
    InAppBrowser,
    DatePipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy }

  //  { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  
}
